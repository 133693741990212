import { css } from '@emotion/react'

// This Font Software is licensed under the SIL Open Font License, Version 1.1 (http://scripts.sil.org/OFL).
// https://github.com/ixkaito/NotoSansJP-subset

import NotoSansJPBoldOtf from '../fonts/NotoSansJP-Bold.otf'
import NotoSansJPBoldWoff from '../fonts/NotoSansJP-Bold.woff'
import NotoSansJPBoldWoff2 from '../fonts/NotoSansJP-Bold.woff2'
import NotoSansMediumOtf from '../fonts/NotoSansJP-Medium.otf'
import NotoSansMediumWoff from '../fonts/NotoSansJP-Medium.woff'
import NotoSansMediumWoff2 from '../fonts/NotoSansJP-Medium.woff2'
import NotoSansJPRegularOtf from '../fonts/NotoSansJP-Regular.otf'
import NotoSansJPRegularWoff from '../fonts/NotoSansJP-Regular.woff'
import NotoSansJPRegularWoff2 from '../fonts/NotoSansJP-Regular.woff2'

export const fontStyles = css`
  @font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    src: url(${NotoSansJPRegularWoff2}) format('woff2'), url(${NotoSansJPRegularWoff}) format('woff'),
      url(${NotoSansJPRegularOtf}) format('opentype');
  }
  @font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 500;
    src: url(${NotoSansMediumWoff2}) format('woff2'), url(${NotoSansMediumWoff}) format('woff'),
      url(${NotoSansMediumOtf}) format('opentype');
  }
  @font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    src: url(${NotoSansJPBoldWoff2}) format('woff2'), url(${NotoSansJPBoldWoff}) format('woff'),
      url(${NotoSansJPBoldOtf}}) format('opentype');
  }
`
