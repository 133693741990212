import { css } from '@emotion/react'
import { rem } from './mixin.styles'
import { colors, fontSize } from './variables.styles'

export const globalStyles = css`
  :root {
    --roman-font-family: 'Arial', sans-serif;
    --base-font-family: 'Noto Sans JP', sans-serif;
  }

  body {
    color: ${colors.body.hex};
    font-family: var(--base-font-family);
    font-size: ${fontSize.md};
  }

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    overflow: hidden;
  }

  // gatsby plugin imageの遅延表示を無効化する
  .gatsby-image-wrapper [data-main-image] {
    transition: none !important;
  }

  h1 {
    font-size: clamp(${rem(28)}, 5.276vw, ${rem(48)});
    line-height: clamp(${rem(41)}, 7.675vw, ${rem(70)});
  }
  h2 {
    font-size: clamp(${rem(24)}, 3.458vw, ${rem(36)});
    /* 中間値はデザインで未指定、安全のためMAXに設定 */
    line-height: clamp(${rem(35)}, 10vw, ${rem(52)});
  }
  h3 {
    font-size: clamp(${rem(20)}, 2.718vw, ${rem(24)});
    /* 中間値はデザインで未指定、安全のためMAXに設定 */
    line-height: clamp(${rem(29)}, 5vw, ${rem(35)});
  }
  /* デザイン上で未設定、一旦、ベースサイズと同じにする */
  h4,
  h5,
  h6 {
    font-size: ${fontSize.md};
  }
  a {
    color: ${colors.mainBlue.hex};
  }

  ol.ol--brackets {
    margin-top: ${rem(8)};
    > li {
      list-style-type: none;
      counter-increment: list;
      margin-left: calc(${rem(10)});
      text-indent: calc(-1.2em - ${rem(10)});
      &::before {
        content: '(' counter(list) ')';
        padding-right: ${rem(10)};
      }
      > ol {
        > li {
          counter-increment: none;
          list-style-type: decimal;
          margin-left: 0;
          text-indent: 0;
          &::before {
            content: none;
          }
        }
      }
    }
  }
`
