import { useEffect, useState } from 'react'

/**
 * @param activePoint header height
 * @return is boolean
 */
export const useHeaderScroll = (activePoint: number): boolean => {
  const [isHeaderActive, setIsHeaderActive] = useState<boolean>(true)
  /**
   *
   * @return is boolean
   */
  const scrollWindow = () => {
    if (activePoint <= window.scrollY) {
      setIsHeaderActive(false)
    } else {
      setIsHeaderActive(true)
    }
  }

  useEffect(() => {
    window.addEventListener('load', scrollWindow)
    window.addEventListener('scroll', scrollWindow)
    return () => {
      window.addEventListener('load', scrollWindow)
      window.removeEventListener('scroll', scrollWindow)
    }
  }, [isHeaderActive])

  return isHeaderActive
}
