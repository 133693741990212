/* Configuration paths and global menu items */

export type PathProps = {
  name: string
  slug: string
  onlyHeader?: boolean
  onlyFooter?: boolean
  pagenations?: boolean
  isSimplePage?: boolean
  perPage?: number
  api?: 'microCMS' | undefined
  isPrimary?: boolean
}

const pathsConfig: PathProps[] = [
  {
    name: 'TOP',
    slug: ''
    // onlyHeader: true
  },
  {
    name: '機能紹介',
    slug: 'feature'
  },
  // コンテンツが作成されるまでコメントアウトで対応します
  // {
  //   name: '導入事例',
  //   slug: 'interviews',
  //   pagenations: true,
  //   perPage: 10,
  //   api: 'microCMS'
  // },
  // {
  //   name: '調達DX',
  //   slug: 'dxs',
  //   pagenations: true,
  //   perPage: 10,
  //   api: 'microCMS'
  // },
  {
    name: 'よくある質問',
    slug: 'faq'
  },
  {
    name: '私たちについて',
    slug: 'about'
  },
  {
    name: 'お問い合わせ',
    slug: 'contact',
    isSimplePage: true
  },
  {
    name: '利用規約',
    slug: 'terms',
    onlyFooter: true,
    isSimplePage: true
  },
  {
    name: 'プライバシーポリシー',
    slug: 'privacy',
    onlyFooter: true,
    isSimplePage: true
  }
]

export default pathsConfig
