import { Global } from '@emotion/react'
import React, { ReactNode } from 'react'

import { fontStyles } from '../../styles/font.styles'
import { globalStyles } from '../../styles/global.styles'
import { utilsStyles } from '../../styles/utils.styles'

import { Footer } from './Footer'
import { Header } from './Header'

type Props = {
  children?: ReactNode
  type?: 'other' | 'top'
}

/**
 * Layout
 *
 * @param children children
 * @returns global header
 */
export const Layout = ({ children, type = 'other' }: Props) => {
  return (
    <>
      <Global styles={[globalStyles, utilsStyles, fontStyles]} />
      <Header type={type} />
      {children}
      <Footer />
    </>
  )
}
