import { css } from '@emotion/react'
import { mq } from './mixin.styles'

export const utilsStyles = css`
  /* Hiding class, making content visible only to screen readers but not visually */
  /* "sr" meaning "screen-reader" */
  /* https://css-tricks.com/inclusively-hidden/ */
  .sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .align-right {
    text-align: right;
  }
  /* media query br contorol */
  .is-sm-only {
    display: inline;
  }
  .is-md-only,
  .is-lg-only {
    display: none;
  }
  ${mq('md')} {
    .is-sm-only {
      display: none;
    }
    .is-md-only {
      display: inline;
    }
  }
  ${mq('lg')} {
    .is-md-only {
      display: none;
    }
    .is-lg-only {
      display: inline;
    }
  }
  .no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
`
