import { constColorsToColorObject } from '../utils/color'
import { BreakpointKeys, FontSizeKeys, HeightKeys, SpaceKeys } from './types'

export const baseFontSize: number = 16

export const mobileSafeSize: number = 44

export const breakpoints: Record<BreakpointKeys, number> = {
  sm: 599,
  md: 789,
  lg: 1024,
  xl: 1200,
  xxl: 1439,
  minContent: 820,
  content: 1000
} as const

export const colors = constColorsToColorObject({
  /* 本文テキスト */
  body: '#333333',
  /* メイン */
  mainBlue: '#007AB7',
  mainGreen: '#1B987D',
  /* イエロー */
  mainYellow: '#FFC452',
  /* セカンダリー */
  secondary: '#00A4B4',
  lightColor: '#CAEAFA',
  skyBlue: '#0DB2E7',
  subBlue: '#1082BC',
  subBlue2: '#38A1DB',
  subBlue3: '#E5F2F8',
  reMainBlue: '#14A1F0',
  /* グレイ */
  grayBorder: '#D6D6D6',
  grayBorder2: '#D9D9D9',
  bgGray: '#f4f4f4',
  textGray: '#FFFDFD',
  textGray2: '#cccccc',
  /* エラー */
  error: '#EB0000',
  white: '#ffffff',
  black: '#000000'
} as const)

// export const rootFontSize = '62.5%'

export const fontSize: Record<FontSizeKeys, `${number}rem; line-height: ${number}`> = {
  xs: `${12 / baseFontSize}rem; line-height: ${17 / 12}`,
  sm: `${14 / baseFontSize}rem; line-height: ${20 / 14}`,
  md: `${16 / baseFontSize}rem; line-height: ${23 / 16}`,
  lg: `${20 / baseFontSize}rem; line-height: ${40 / 20}`,
  xl: `${24 / baseFontSize}rem; line-height: ${40 / 24}`,
  xxl: `${28 / baseFontSize}rem; line-height: ${41 / 28}`
}

export const height: Record<HeightKeys, number> = {
  header: 60,
  headerMax: 80,
  footer: 0,
  footerMax: 0
}

export const space: Record<SpaceKeys, `${number}rem`> = {
  xs: `${8 / baseFontSize}rem`,
  sm: `${16 / baseFontSize}rem`,
  md: `${20 / baseFontSize}rem`,
  lg: `${30 / baseFontSize}rem`,
  xl: `${36 / baseFontSize}rem`,
  xxl: `${40 / baseFontSize}rem`
}
