import { BreakpointKeys } from './types'
import { baseFontSize, breakpoints } from './variables.styles'

/**
 * Media Query
 * @param key ブレークポイントKey
 * @returns min-widthのMedia Query
 */
export const mq = (key: BreakpointKeys): string => {
  return `@media (min-width: ${breakpoints[key] + 1}px)`
}

/**
 * rem
 *
 * @param pixcels pixcel size
 * @returns rem
 */
export const rem = (pixcels: number): string => {
  return `${Number.parseFloat((pixcels / baseFontSize).toFixed(3))}rem`
}
