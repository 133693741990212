import { css } from '@emotion/react'
import { Link } from 'gatsby'
import React, { ReactNode } from 'react'
import buttonArrowWhite from '../../images/button-arrow-white.svg'
import buttonArrowYellow from '../../images/button-arrow-yellow.svg'
import buttonArrow from '../../images/button-arrow.svg'
import { rem } from '../../styles/mixin.styles'
import { colors, fontSize } from '../../styles/variables.styles'

type ButtonProps = {
  link: string
  children: ReactNode
  style?: `primary` | `primary-yellow` | `secondary`
}

/**
 * ButtonLink
 *
 * @param ButtonProps card props
 * @returns button sytled link contents
 */
export const ButtonLink = ({ link, children, style }: ButtonProps) => {
  /**
   * buttonStyles
   *
   * @return button styles
   */
  const buttonStyles = () => {
    switch (style) {
      case `primary`:
        return primaryStyle
      case `secondary`:
        return secondaryStyle
      case `primary-yellow`:
        return secondaryYellowStyle
      default:
        return defaultStyle
    }
  }

  return (
    <div css={buttonStyles}>
      <Link className="link-button" to={link}>
        {children}
      </Link>
    </div>
  )
}

const defaultStyle = css`
  .link-button {
    border: ${rem(2)} solid ${colors.mainBlue.hex};
    color: ${colors.mainBlue.hex};
    background-color: ${colors.white.hex};
    font-size: ${fontSize.sm};
    font-weight: 700;
    padding: ${rem(4)} ${rem(24)} ${rem(6)} ${rem(24)};
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12));
    text-decoration: none;
    &:hover {
      color: rgba(${colors.mainBlue.rgb}, 0.8);
      border-color: rgba(${colors.mainBlue.rgb}, 0.8);
      filter: none;
    }
  }
`

const primaryStyle = css`
  max-width: ${rem(320)};
  margin: 0 auto;
  .link-button {
    border: ${rem(2)} solid ${colors.mainBlue.hex};
    border-radius: 50em;
    background-color: ${colors.white.hex};
    display: flex;
    justify-content: center;
    padding: ${rem(12)} ${rem(45)};
    text-decoration: none;
    font-size: ${rem(18)};
    line-height: ${29 / 20};
    font-weight: 700;
    color: ${colors.subBlue.hex};
    transition: box-shadow 300ms;
    box-shadow: 0px 2px 4px 0px rgba(${colors.black.rgb}, 0.12);
    position: relative;
    transition: all 400ms ease;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: ${rem(16)};
      bottom: 0;
      margin: auto;
      background-image: url(${buttonArrow});
      width: ${rem(24)};
      height: ${rem(24)};
    }

    &:hover {
      color: ${colors.white.hex};
      background-color: ${colors.mainBlue.hex};
      box-shadow: none;
      &::before {
        background-image: url(${buttonArrowWhite});
      }
    }
  }
`

const secondaryYellowStyle = css`
  ${primaryStyle}
  .link-button {
    color: ${colors.body.hex};
    border-color: ${colors.mainYellow.hex};
    background-color: ${colors.mainYellow.hex};
    &::before {
      background-image: url(${buttonArrowWhite});
    }

    &:hover {
      color: ${colors.body.hex};
      background-color: ${colors.white.hex};
      &::before {
        background-image: url(${buttonArrowYellow});
      }
    }
  }
`

const secondaryStyle = css``
