import { css } from '@emotion/react'
import { Link } from 'gatsby'
import React, { ReactNode } from 'react'

import pathsConfig from '../../../paths-config'
import logoUrl from '../../images/logo.svg'
import { mq, rem } from '../../styles/mixin.styles'
import { space, breakpoints, fontSize, colors } from '../../styles/variables.styles'
import { generatePath } from '../../utils/customPath'

import { GlobalNav } from './GlobalNav'

type Props = {
  children?: ReactNode
}

/**
 * Footer
 *
 * @param children children
 * @returns global footer
 */
export const Footer = ({ children }: Props) => {
  const forFooterSubMenu = pathsConfig.filter((output) => {
    return output.onlyFooter === true
  })
  return (
    <footer css={footerStyles}>
      {children}
      <div className="footer-branding">
        <picture>
          <source media={`(min-width: ${breakpoints.lg}px)`} srcSet={logoUrl} width={226} height={96} />
          <img className="site-logo" src={logoUrl} width={142} height={60} alt="iPaS" />
        </picture>
      </div>
      <GlobalNav isFooter />
      <ul className="sub-menu">
        {forFooterSubMenu.map((path, index) => (
          <li key={index} className="sub-menu__item">
            <Link to={generatePath(path.slug)} className="sub-menu__link">
              <span>{path.name}</span>
            </Link>
          </li>
        ))}
      </ul>
      <div className="copyright">© 2022 Isuzu Corporation All Rights Reserved.</div>
    </footer>
  )
}

const footerStyles = css`
  width: min(calc(100% - ${space.md} * 2), ${breakpoints.content}px);
  padding-top: clamp(calc(${space.md} * 2), 5.56vw, calc(${space.md} * 4));
  padding-bottom: clamp(calc(${space.md} * 2), 5.56vw, calc(${space.md} * 4));
  margin-left: auto;
  margin-right: auto;
  display: grid;
  flex-direction: column;
  align-items: flex-start;
  grid-template-areas:
    'logo menu'
    'sub-menu sub-menu'
    'copyright copyright';
  .footer-branding {
    display: flex;
    justify-content: flex-end;
    margin-left: ${rem(8)};
    margin-right: ${rem(28)};
    grid-area: logo;
    .site-description {
      font-size: ${fontSize.xs};
      font-weight: 500;
    }
    .site-logo {
      width: clamp(142px, 22.223vw, 226px);
    }
  }
  > nav {
    height: auto;
    grid-area: menu;
    margin-top: ${rem(-14)};
    margin-left: clamp(${space.xs}, 5.7vw, ${rem(49 - 28)});
  }
  .sub-menu {
    grid-area: sub-menu;
    display: flex;
    justify-content: center;
    gap: clamp(${space.md}, 8vw, ${rem(48)});
    margin-top: ${rem(17.5)};
    .sub-menu__item {
      list-style: none;
      .sub-menu__link {
        font-size: ${fontSize.sm};
        text-decoration: none;
        color: ${colors.body.hex};
        display: flex;
        padding-top: ${rem(12)};
        padding-bottom: ${rem(12)};
        &:hover {
          color: ${colors.mainBlue.hex};
        }
      }
    }
  }
  .copyright {
    font-size: ${fontSize.sm};
    line-height: ${rem(16)};
    grid-area: copyright;
    justify-self: center;
    margin-top: ${rem(28)};
  }
  ${mq('lg')} {
    width: min(calc(100% - ${rem(70)} * 2), ${breakpoints.content}px);
    grid-template-areas:
      'logo menu menu'
      'logo sub-menu copyright';
    grid-template-columns: 1fr 1fr max-content;
    grid-template-rows: 1fr 1fr;

    .footer-branding {
      justify-content: flex-start;
      img {
        width: ${rem(226)};
        height: ${rem(96)};
      }
    }
    > nav {
      margin-top: ${rem(23)};
    }
    .sub-menu {
      margin-top: 0;
      justify-self: flex-start;
      /* ↓上部のメニューと文頭を合わせて位置を調整（メニュー増減時に変更する） */
      /* margin-left: clamp(${rem(34)}, 3.5vw, ${rem(58)}); */
      margin-left: ${rem(20)};
      gap: ${rem(39)};
      .sub-menu__item {
        .sub-menu__link {
          padding-top: ${rem(10)};
          padding-bottom: ${rem(14)};
        }
      }
    }
    .copyright {
      margin-top: ${rem(12)};
      margin-left: ${rem(40)};
      justify-self: flex-end;
    }
  }
  ${mq('xl')} {
    .copyright {
      margin-left: ${rem(58)};
    }
  }
`
