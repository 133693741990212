import { css } from '@emotion/react'
import { Link } from 'gatsby'
import React, { ReactNode } from 'react'

import { useHeaderScroll } from '../../hooks/useHeaderScroll'
import headerLogoWhite from '../../images/header-logo-w.svg'
import headerLogo from '../../images/header-logo.svg'
import { mq, rem } from '../../styles/mixin.styles'
import { breakpoints, colors, space, height, fontSize } from '../../styles/variables.styles'
import { generatePath } from '../../utils/customPath'
import { GlobalNav } from './GlobalNav'

type Props = {
  children?: ReactNode
  type?: 'other' | 'top'
}

/**
 * Header
 *
 * @param children children
 * @param type header style type
 * @returns global header
 */
export const Header = ({ children, type = 'other' }: Props) => {
  const scrollActive = useHeaderScroll(100)

  return (
    <header css={[headerStyles, type === 'top' && topBaseStyles, scrollActive && type === 'top' && topNoScrollStyles]}>
      <div className="header-inner">
        <h1 className="site-title">
          <Link to={generatePath()}>
            <div className="header-inner__logo">
              {type === 'top' && scrollActive ? (
                <img src={headerLogoWhite} width="117" height="91" alt="iPaS" />
              ) : (
                <img src={headerLogo} width="117" height="91" alt="iPaS" />
              )}
            </div>
            <span>パレット調達支援システム</span>
          </Link>
        </h1>
        <GlobalNav type={scrollActive ? type : 'other'} />
        {children}
      </div>
    </header>
  )
}

const headerStyles = css`
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
  display: flex;
  position: fixed;
  z-index: 100;
  justify-content: center;
  background-color: rgba(${colors.white.rgb}, 1);
  transition: background-color 300ms cubic-bezier(0.85, 0, 0.15, 1), box-shadow 300ms cubic-bezier(0.85, 0, 0.15, 1);
  .header-inner {
    width: min(100%, ${breakpoints.xxl}px);
    height: clamp(${rem(height.header)}, 7vw, ${rem(height.headerMax)});
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .site-title {
    padding-left: clamp(${space.md}, 4vw, ${space.lg});
    padding-right: clamp(${space.md}, 4vw, ${space.lg});
    justify-content: flex-start;
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      column-gap: ${rem(5)};
    }
    img {
      display: flex;
    }
    span {
      font-size: ${rem(12)};
      line-height: 1;
      font-weight: 700;
      color: ${colors.body.hex};
    }
  }
  .header-inner__logo {
    img {
      max-width: ${rem(70)};
    }
  }
  ${mq('lg')} {
    .site-title {
      a {
        column-gap: ${rem(14)};
      }
      span {
        font-size: ${rem(16)};
      }
    }
    .header-inner__logo {
      img {
        max-width: ${rem(117)};
      }
    }
  }
  ${mq('xl')} {
    .site-title {
      span {
        font-size: ${rem(20)};
      }
    }
  }
`

const topBaseStyles = css`
  transition: background-color 350ms cubic-bezier(0, 0.55, 0.45, 1), box-shadow 300ms cubic-bezier(0.85, 0, 0.15, 1);
`

const topNoScrollStyles = css`
  position: absolute;
  background-color: rgba(${colors.white.rgb}, 0);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0);
  .header-inner {
    justify-content: flex-end;
    position: relative;
  }
  .site-title {
    position: absolute;
    left: 0;
    padding-top: ${rem(53)};
    padding-left: ${rem(31)};
    > a {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: ${rem(6)};
    }
    img {
      width: ${rem(151)};
      height: ${rem(65)};
      max-width: none;
    }
    span {
      font-size: ${fontSize.sm};
      letter-spacing: 0.045em;
      color: ${colors.white.hex};
    }
  }

  ${mq('xl')} {
    .site-title {
      padding-top: ${rem(108)};
      padding-left: min(4vw, ${rem(127)});
      > a {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        gap: ${rem(7)};
      }
      img {
        width: min(18vw, ${rem(268)});
        height: min(9vw, ${rem(118)});
      }
      span {
        font-size: min(2vw, ${rem(28)});
        line-height: ${rem(41)};
      }
      &::after {
        content: '';
        display: flex;
        width: min(45vw, ${rem(625)});
        height: ${rem(4)};
        margin-top: ${rem(13)};
        background-color: ${colors.white.hex};
      }
    }
  }
  ${mq('xxl')} {
    .site-title {
      padding-left: ${rem(127)};
      img {
        width: ${rem(268)};
        height: ${rem(118)};
      }
      span {
        font-size: ${rem(28)};
        margin-bottom: ${rem(-5)};
      }
      &::after {
        width: ${rem(625)};
      }
    }
  }
`
