import { GatsbySeo, GatsbySeoProps } from 'gatsby-plugin-next-seo'
import React from 'react'

const titleTemplate = '%s｜iPaS｜パレット調達支援システム'

/**
 * Seo
 *
 * @param props props
 * @returns GatsbySeo tag
 */
export const Seo: React.FC<GatsbySeoProps> = ({ ...props }) => {
  return <GatsbySeo titleTemplate={props.title ? titleTemplate : '%s'} {...props} />
}
