import pathsConfig from '../../paths-config'

/**
 * getPathName
 *
 * Get path name by slug from paths-config
 *
 * @param slug path's slug
 * @return path's name
 */
export const getPathName = (slug: string = ''): string => {
  const pathObj = pathsConfig.filter((path) => path.slug === slug)[0]
  if (!pathObj) {
    return ''
  }
  return pathObj.name
}

/**
 * getPerPage
 *
 * Get per page number by slug from paths-config
 *
 * @param slug path's slug
 * @return path's perPage number. default 10
 */
export const getPerPage = (slug: string): number => {
  const pathObj = pathsConfig.filter((path) => path.slug === slug)[0]
  const perPage = pathObj.perPage
  if (!perPage) {
    return 10
  }
  return perPage
}

/**
 * generatePath
 *
 * @param slug path's slug
 * @param pagesNumber pages number
 * @param pageId page id
 * @param isAbsolute absolute path or not
 * @return relative path or absolute path string
 */
export const generatePath = (
  slug?: string,
  pagesNumber?: number,
  pageId?: GatsbyTypes.Maybe<string>,
  isAbsolute?: boolean
): string => {
  const firstDir = '/'
  let secondDir = ''
  let thirdDir = ''
  if (slug) {
    secondDir = `${slug}/`
  }
  if (pagesNumber) {
    thirdDir = `page/${pagesNumber}/`
  } else if (pageId) {
    thirdDir = `${pageId}/`
  }
  const relativePath = firstDir + secondDir + thirdDir
  if (!isAbsolute) {
    return relativePath
  }
  return `${process.env.GATSBY_SITE_URL!}${secondDir}${thirdDir}`
}

/**
 * isSinglePathPage
 *
 * @param slug slug name
 * @return path page style is simple or not
 */
export const isSinglePathPage = (slug: string): boolean => {
  const pathObj = pathsConfig.filter((path) => path.slug === slug)[0]
  if (pathObj.isSimplePage) {
    return true
  }
  return false
}
