/**
 * カラーコードをHexからRgb文字列に変換する
 * @param hex string for color hex
 * @returns hex to `00, 00, 00`
 */
export const hexToRgb = (hex: string): string => {
  const result = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (result) {
    const r = parseInt(result[1], 16)
    const g = parseInt(result[2], 16)
    const b = parseInt(result[3], 16)
    return `${r},${g},${b}`
  }

  throw new Error(`the result of the run was null. the argument is "${hex}".`)
}

export type CreateColorObject = <Colors>(colors: Colors) => {
  [K in keyof Colors]: {
    hex: typeof colors[K]
    rgb: string
  }
}

/**
 * カラーコードを呼び出すためのカラーオブジェクトを生成する。
 * @param colors color定数
 * @returns rgbとhexを呼び出すことのできるカラーオブジェクト
 */
export const constColorsToColorObject: CreateColorObject = (colors) => {
  return Object.assign(
    {},
    ...Object.entries(colors).map(([key, hex]) => {
      return {
        [key]: {
          hex: hex,
          rgb: hexToRgb(hex)
        }
      }
    })
  )
}
